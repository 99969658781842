import api from "./api";

function getAllPlans(dataInicial, dataFinal) {
    return api.get(`/planejado/plans/${dataInicial}/${dataFinal}`);
}

function getPlansByUserAndDate(params){
    const [accountId, dataInicial, dataFinal] = params;
    return api.get(`/relatorio-capacidade/user-plans/${accountId}/${dataInicial}/${dataFinal}`)
}

function getPlansByProjectAndDate(params){
    const [dataInicial, dataFinal, projectIds] = params;
    return api.get(`/relatorio-capacidade/project-plans/${projectIds}/${dataInicial}/${dataFinal}`)
}

function getUserScheduleByUserAndDate(params) {
    const [accountId, dataInicial, dataFinal] = params;
    return api.get(`/relatorio-capacidade/user-schedule/${accountId}/${dataInicial}/${dataFinal}`)
}

function getUserWorklogByUserAndDate(params){
    const [accountId, dataInicial, dataFinal] = params;
    return api.get(`/relatorio-capacidade/user-worklog/${accountId}/${dataInicial}/${dataFinal}`)
}

function getProjectWorklogByUserAndDate(params){
    const [dataInicial, dataFinal, ids] = params;
    return api.get(`/relatorio-capacidade/project-worklog/${ids}/${dataInicial}/${dataFinal}`)
}

function getDataCapacityByIdAndDate(dataInicial, dataFinal){
    return api.get(`/relatorio-capacidade/tabela/${dataInicial}/${dataFinal}`)
}

function syncAllUsersConvertToJira(){
    return api.get(`/relatorio-capacidade/jira-users/`)
}

function getUserWorkload(accountId){
    return api.get(`/relatorio-capacidade/workload/${accountId}`)
}

const PlanejamentoApiService = { 
    getAllPlans, 
    getPlansByUserAndDate, 
    getPlansByProjectAndDate,
    getUserScheduleByUserAndDate, 
    getUserWorklogByUserAndDate,
    getProjectWorklogByUserAndDate, 
    syncAllUsersConvertToJira, 
    getDataCapacityByIdAndDate,
    getUserWorkload
}

export default PlanejamentoApiService