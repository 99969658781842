import React, { useState, useEffect, useMemo } from "react";
import PageContainer from "../../../components/PageContainer";
import { useLocation } from 'react-router-dom';
import { MdOutlineArrowBack } from "react-icons/md";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { Button, Table } from "react-bootstrap";
import UtilService from "../../../services/util";
import ClienteoApiService from "../../../services/api/CadastroClientesService";
import ProjetosApiService from "../../../services/api/ProjetosApiService";
import Loaging from "../../../components/Loading";
import ConvertDataService from "../../../services/util/ConvertDatas";

function HistoricoCustosProjetosClientesView() {
    const location = useLocation();
    const { data } = location.state || {};
    const history = useHistory();

    const [projetosList, setProjetosList] = useState([]);

    const [custosAgrupados, setCustosAgrupados] = useState({});
    const [custos, setCustos] = useState([]);
    const [valorHH, setValorHH] = useState([]);
    const [loading, setLoading] = useState(false);
    const [tipoCustoHH, settTpoCustoHH] = useState(false);
    const [clientForProject, setClientForProject] = useState([])
    const [allProjects, setAllProjects] = useState([]);

    useEffect(() => {
        const agruparCustos = () => {
            const agrupados = custos.reduce((acc, custo) => {
                const key = `${custo.projeto}-${ConvertDataService.convertDataStrToYYYYmm(custo.periodo_inicio)}`;
                if (!acc[key]) {
                    acc[key] = { projeto: custo.projeto, mes: ConvertDataService.convertDataStrToYYYYmm(custo.periodo_inicio), valorTotal: 0 };
                }
                acc[key].valorTotal += parseFloat(custo.valor);
                return acc;
            }, {});

            setCustosAgrupados(agrupados);
        };

        agruparCustos();
    }, [custos]);

    const getHistoricoCusto = async () => {
        const KeysDoProjeto = data.projetosSelecionados.map(item => item.value);
        const [dayI, monthI, yearI] = data.periodoInicial.split('/');
        const [day, month, year] = data.periodoFinal.split('/');

        const req = {
            "tipoCusto": data.tipoCusto,
            "periodoInicial": `${yearI}-${monthI}-${dayI}`,
            "periodoFinal": `${year}-${month}-${day}`,
            "KeysDoProjeto": KeysDoProjeto
        }

        if(KeysDoProjeto[0] == "TODOS") {
            try {
                const resp = await ProjetosApiService.findAllByPeriod(req.tipoCusto, req.periodoInicial, req.periodoFinal)
                separarPorProjeto(resp.data);
                setCustos(resp.data);
            } catch (error) {
                console.error("Erro ao buscar historico de custo de TODOS os projetos.", error)
            }
        } else {
            try {
                const resp = await ClienteoApiService.getHistoricoCusto(req)
                separarPorProjeto(resp.data.result);
                setCustos(resp.data.result);
            } catch (error) {
                console.error("Erro ao buscar historico de custo.", error)
            }
        }
        
    };

    const getClientForProject = async () => {
        const requisicoes = data.projetosSelecionados.map(async (projeto) => {
            const resposta = await ClienteoApiService.getClientByProjeto(projeto.value);
            const nomeCliente = resposta.data?.result
                const reqData = {
                    projeto: projeto.label,
                    cliente: nomeCliente ? nomeCliente.nome : ""
                }
            return reqData;
        });
    
        Promise.all(requisicoes).then((respostasIndividuais) => {
            setClientForProject((estadoAnterior) => [...estadoAnterior, ...respostasIndividuais]);
        });
    }

    const getValorHHAllProject = async (AllKeys, dataInicial, dataFinal) => {
        try {
            const promises = AllKeys.map(async (key) => {
                if (!key.key.includes(",")){
                    const respHH = await ProjetosApiService.getDetailProject(key.key, dataInicial, dataFinal)
                    return { key: key, totalHH: respHH.data.total };
                }
            });
            const responseData = await Promise.all(promises);
            setValorHH(responseData);
            setLoading(false);
        } catch (error) {
            setLoading(false);
            console.error("Erro ao buscar Valor HH.", error)
        } 
    }

    const getValorHH = async (keys, dataInicial, dataFinal) => {
        setLoading(true)
        if(keys[0].value == "TODOS") {
            try {
                const allProjects = await ProjetosApiService.getAllProject();
                setAllProjects(allProjects.data.data);
                getValorHHAllProject(allProjects.data.data, dataInicial, dataFinal)
            } catch (error) {
                console.error("Erro ao buscar Valor HH de todos os projetos.", error)
            }           

        } else {
            try {
                const promises = keys.map(async (key) => {
                    const respHH = await ProjetosApiService.getDetailProject(key.value, dataInicial, dataFinal)
                    return { key: key, totalHH: respHH.data.total };
                });
                const responseData = await Promise.all(promises);
                setValorHH(responseData);
                adicionarValorHH(responseData);
                setLoading(false);
            } catch (error) {
                setLoading(false);
                console.error("Erro ao buscar Valor HH.", error)
            }
        }

    }

    const exportDataInExcelHH = async (clientNames) => {
        const header = [
            ['Cliente', 'Projeto', 'Categoria', 'Período Inicial', 'Período Final', 'Valor', 'Tipo de Custo']
        ];
        const formatData = (dataProps) => {
            const formatProject = (projeto) => {
                let [day, month, year] = (data.periodoInicial).split('/');
                let [dayFin, monthFin, yearFin] = (data.periodoFinal).split('/');
                const nomeCliente = clientForProject.find(projectName => projectName.projeto == projeto.key.value)?.cliente || "";
                

                return {
                    cliente: nomeCliente,
                    projeto: projeto.key.value,
                    categoria: getProjectCategory(projeto.key.value),
                    periodoInicial: `${month}/${year}`,
                    periodoFinal: `${monthFin}/${yearFin}`,
                    valor: projeto.totalHH.toLocaleString('pt-br', { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
                    tipo_custo: "VALOR_HH",
                };
        
            };
        
            let formattedData = [];
            dataProps.forEach((project) => {
                formattedData.push(formatProject(project));
            });
            return formattedData;
        };
        
        const formatedData = formatData(clientNames);
        
        const gerarNomeArquivo = (projetoItems) => {
            let [day, month, year] = (projetoItems.periodoInicial).split('/');
            let [dayFin, monthFin, yearFin] = (projetoItems.periodoFinal).split('/');
        
            return `histórico_custo:${month}-${year}_${monthFin}-${yearFin}`;
        };
        const nomeArquivo = gerarNomeArquivo(data);
        
        UtilService.exportXls(formatedData, nomeArquivo, header);
    
    };

    const TabelaValorHH = ({ labelTotal }) => {
        const hasValidData = useMemo(() => valorHH.some(dataHH => dataHH.totalHH > 0), [valorHH]);
        const [clientNames, setClientNames] = useState({});

        useEffect(() => {
            const fetchClientNames = async () => {
                const names = {};
                for (const projeto of Object.keys(projetosList)) {
                    const nome = await getClientByProject(projeto);
                    names[projeto] = nome;
                }
                setClientNames(names);
            };

            fetchClientNames();
        }, [projetosList]);

        return (
            <>
                {hasValidData ? (
                    <>
                        <Button onClick={() => exportDataInExcelHH(valorHH)} className='mb-3'>Exportar</Button>
                        {valorHH.map((dataHH, index) => (
                            dataHH.totalHH > 0 && (
                                <div key={index} className="mb-5">
                                    {data.radioValue == "cliente" ?
                                        <><h5><b>Cliente:</b> {data.cliente}</h5> <h5><b>Projeto:</b> {getProjectLabel(dataHH.key.value)}</h5> <h5><b>Categoria:</b> {getProjectCategory(dataHH.key.value)}</h5> </>
                                        :
                                        <><h5><b>Cliente:</b> {clientNames[dataHH.key.value] ? `${clientNames[dataHH.key.value]}` : " não cadastrado"}</h5> <h5><b>Projeto:</b> {getProjectLabel(dataHH.key.value)}</h5> <h5><b>Categoria:</b> {getProjectCategory(dataHH.key.value)}</h5> </>
                                    }
                                    <Table striped bordered hover>
                                        <thead>
                                            <tr>
                                                <th className="w-25">#</th>
                                                <th className="w-25">Período</th>
                                                <th className="w-25">Tipo de Custo</th>
                                                <th className="w-25">Valor</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>{labelTotal ? index+1 : "Total"}</td>
                                                <td>{data.periodoInicial} - {data.periodoFinal}</td>
                                                <td>{labelTotal ? "VALOR_HH" :data.tipoCusto}</td>
                                                <td>R$ {dataHH.totalHH.toLocaleString('pt-br', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
                                            </tr>
                                        </tbody>
                                    </Table>
                                </div>
                            )
                        ))}
                    </>
                ) : (
                    <h5>Não existe dados de custos para os filtros selecionados</h5>
                )}
            </>
        );
    };

    const getAllProjects = async () =>{
        try {
            const allProjects = await ProjetosApiService.getAllProject();
            setAllProjects(allProjects.data.data);
        } catch (error) {
            console.error("Erro ao buscar Valor HH de todos os projetos.", error)
        }
    }

    useEffect(() => {  
        getHistoricoCusto();     
        if(data.tipoCusto == "VALOR_HH" || data.tipoCusto == "total") {
            getValorHH(data.projetosSelecionados, data.periodoInicial, data.periodoFinal);
        }
        if(data.tipoCusto == "VALOR_HH"){
            settTpoCustoHH(true);
            getClientForProject();
        }
        if(data.radioValue == "projeto"){
            getAllProjects();
        }
    
    }, []);

    const handleCancel = async () => {
        history.push('../relatorios-delivery/');
    }

    const actions = [
        {
            label: 'Voltar',
            icon: <MdOutlineArrowBack />,
            color: 'info',
            type: 'button',
            onClick: handleCancel
        }
    ]

    const separarPorProjeto = (data) => {
        const groupedData = data.reduce((acc, item) => {
            if (!acc[item.projeto]) {
                acc[item.projeto] = { custosIndiretos : [], valorHH: null };
            }
            acc[item.projeto].custosIndiretos.push(item);
            return acc;
        }, {});
        setProjetosList(groupedData);
    };

    const adicionarValorHH = (valorHH) => {
        setProjetosList((prev) => {
            const updatedList = { ...prev };
            valorHH.forEach((vh) => {
                if (vh.totalHH > 0) {
                    if (!updatedList[vh.key.value]) {
                        updatedList[vh.key.value] = { custosIndiretos: [], valorHH: vh };
                    } else {
                        updatedList[vh.key.value].valorHH = vh;
                    }
                }
            });
            return updatedList;
        });
    };

    const exportDataInExcel = async (clientNames) => {
        const header = [
            ['Cliente', 'Projeto', 'Categoria', 'Período Inicial', 'Período Final', 'Valor', 'Tipo de Custo']
        ];

        if(data.tipoCusto != "total") {
            const groupedData = Object.keys(projetosList).reduce((acc, projeto) => {
                projetosList[projeto].forEach(item => {
                    const periodoInicio = ConvertDataService.convertDataStrToMMyyyy(item.periodo_inicio);
                    const periodoFim = ConvertDataService.convertDataStrToMMyyyy(item.periodo_fim);
                    const valor = item.cotacao == 0 ? parseFloat(item.valor) : parseFloat(item.valor) * parseFloat(item.cotacao);
                    const key = `${projeto}-${periodoInicio}-${periodoFim}`;
                    const cliente = clientNames[projeto];
                    const categoria = getProjectCategory(projeto);
                    const categoriaTratada = categoria.includes("não encontrada") ? "" : categoria 
                    const projetoName = getProjectLabel(projeto);

                    if (!acc[key]) {
                        acc[key] = {  cliente, projetoName, categoriaTratada, periodoInicio, periodoFim, valor: 0, tipoDeCusto: item.tipo_custo };
                    }
                    acc[key].valor += valor;
                });
                return acc;
            }, {});

            const formatedData = Object.values(groupedData).map(item => {
                return {
                    ...item,
                    valor: item.valor.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })
                };
            });

            const gerarNomeArquivo = (projetoItems) => {
                let [day, month, year] = (projetoItems.periodoInicial).split('/');
                let [dayFin, monthFin, yearFin] = (projetoItems.periodoFinal).split('/');
    
                return `histórico_custo:${month}-${year}_${monthFin}-${yearFin}`;
            };
    
            const nomeArquivo = gerarNomeArquivo(data);
    
            UtilService.exportXls(formatedData, nomeArquivo, header);
        } else {
            const formatData = (dataProps) => {
                const formatProject = (projectName, items) => {
                    const projectHH = valorHH.find(project => project.key.value === projectName)?.totalHH || 0;
                    let [day, month, year] = (data.periodoInicial).split('/');
                    let [dayFin, monthFin, yearFin] = (data.periodoFinal).split('/');
                    const categoria = getProjectCategory(projectName);
                    const categoriaTratada = categoria.includes("não encontrada") ? "" : categoria 

                    const formattedItems = items.custosIndiretos.map((item) => {
                        const { periodo_inicio, periodo_fim, valor, cotacao, tipo_custo } = item;
                        const formattedValue = cotacao !== "0.00" ? parseFloat(valor) * parseFloat(cotacao) : parseFloat(valor);
                        const cliente = clientNames[projectName]
                    
                        return {
                            cliente,
                            projeto: getProjectLabel(projectName),
                            categoriaTratada,
                            periodoInicial: ConvertDataService.convertDataStrToMMyyyy(periodo_inicio),
                            periodoFinal: ConvertDataService.convertDataStrToMMyyyy(periodo_fim),
                            valor: formattedValue,
                            tipo_custo,
                        }
                    });
                    
                    const extraLine = {
                        cliente: clientNames[projectName],
                        projeto: getProjectLabel(projectName),
                        categoriaTratada,
                        periodoInicial: `${month}/${year}`,
                        periodoFinal: `${monthFin}/${yearFin}`,
                        valor: projectHH,
                        tipo_custo: "VALOR_HH",
                        
                    };
            
                    return [...formattedItems, extraLine];
                };
            
                let formattedData = [];
                Object.keys(dataProps).forEach((project) => {
                    formattedData = [...formattedData, ...formatProject(project, dataProps[project])];
                });
            
                return formattedData;
            };
            
            const formatedData = formatData(projetosList);
            
            const gerarNomeArquivo = (projetoItems) => {
            
                let [day, month, year] = (projetoItems.periodoInicial).split('/');
                let [dayFin, monthFin, yearFin] = (projetoItems.periodoFinal).split('/');
            
                return `histórico_custo:${month}-${year}_${monthFin}-${yearFin}`;
            };
            const nomeArquivo = gerarNomeArquivo(data);
            
            UtilService.exportXls(formatedData, nomeArquivo, header);
        }
    };

    const calculateTotals = (dataProps) => {
        const sums = {};

        dataProps.custosIndiretos.forEach(item => {
            const date = new Date(item.periodo_inicio);
            const monthYear = `${date.getMonth() + 1}-${date.getFullYear()}`;
            const dateFinal = new Date(item.periodo_fim);
            const monthYearFinal = `${dateFinal.getMonth() + 1}-${dateFinal.getFullYear()}`;
            const periodoCompleto = `${monthYear} - ${monthYearFinal}`
            const key = `${monthYear}-${item.tipo_custo}`;
            if (!sums[key]) {
                sums[key] = {
                    periodoCompleto,
                    tipo_custo: item.tipo_custo,
                    total: 0,
                    key: item.projeto
                };
            }

            sums[key].total += item.cotacao == 0 ? parseFloat(item.valor) : parseFloat(item.valor) * parseFloat(item.cotacao);
        });

        function transformDate(dateString, periodo) {
            let dates = dateString.split(' - ');

            if(periodo === "inicio") {
                let [month, year] = dates[0].split('-')
                return month < 10 ? `0${month}/${year}` : `${month}/${year}`;
            } else {
                let [month, year] = dates[1].split('-')
                return month < 10 ? `0${month}/${year}` : `${month}/${year}`;
            }
        }

        function calculateTotalsByProject(dataValor, valorHH) {
            let totalResult = 0 

            dataValor.map((total) => (totalResult += total.total))

            totalResult+=valorHH

            return totalResult.toLocaleString("pt-br", { minimumFractionDigits: 2, maximumFractionDigits: 2 })
        }

        function getDatePeriodo(date) {
            let [day, month, year] = date.split('/')

            return `${month}/${year}`
        }
        var indexListTable = 0;
        
        return (
            <>
            {Object.values(sums).map((dado, index) => {
                indexListTable++ 
                return (
                    <tr key={dado.key}>
                        <td>{indexListTable}</td>
                        <td><b></b>{transformDate(dado.periodoCompleto, "inicio")} - {transformDate(dado.periodoCompleto, "fim")}</td>
                        <td>{dado.tipo_custo}</td>
                        <td>R$ {dado.total.toLocaleString('pt-br', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
                    </tr>
                )

            })}
            {data.tipoCusto == "total" && "VALOR_HH" ?
            <tr>
                <td>{indexListTable+1}</td>
                <td>
                    {getDatePeriodo(data.periodoInicial)} - {getDatePeriodo(data.periodoFinal)}
                </td>
                <td>{data.tipoCusto == "total" && "VALOR_HH"}</td>
                <td>R$ {dataProps.valorHH !== null && dataProps.valorHH.totalHH.toLocaleString('pt-br', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) || "0,00"}</td>
            </tr>
            : <></> }
            <tr>
                <td>
                    <b>Total</b>
                </td>
                <td>
                    <b>{getDatePeriodo(data.periodoInicial)} - {getDatePeriodo(data.periodoFinal)}</b>
                </td>
                <td><b>{data.tipoCusto == "total" && "TODOS"}</b></td>
                <td><b>R$ {calculateTotalsByProject(Object.values(sums), dataProps.valorHH !== null && dataProps.valorHH.totalHH || 0)}</b></td>
            </tr>
            </>
        )
    };

    const getClientByProject = async (key) => {
        try {
            const req = await ClienteoApiService.getClientByProjeto(key);
            return req.data.result;
        } catch (error) {
            console.error("Erro ao buscar cliente.", error);
            return null;
        }
    };

    const getProjectLabel = (searchValue) => {
        const values = searchValue.split(',').map(v => v.trim());
        const source = allProjects.length > 0 ? allProjects : data.projetosSelecionados;
        const keyField = allProjects.length > 0 ? 'key' : 'value';
        const nameField = allProjects.length > 0 ? 'name' : 'label';
    
        const labels = values.map(singleValue => {
            for (const projeto of source) {
                if (projeto[keyField].includes(singleValue)) {
                    return projeto[nameField];
                }
            }
            return null;
        });
    
        return labels.filter(label => label !== null).join(', ') || null;
    };

    const getProjectCategory = (searchValue) => {
        const values = searchValue.split(',').map(v => v.trim());
        const categories = values.map(singleValue => {
            if (data.projetosSelecionados[0].value === "TODOS") {
                const item = allProjects.find(item => item.key === singleValue);
                return item ? item.category : 'não encontrada';
            } else {
                const projeto = data.projetosSelecionados.find(projeto => projeto.value === singleValue);
                return projeto ? projeto.categoria : 'não encontrada';
            }
        });
    
        return categories.join(', ');
    };    

    const Tabela = () => {
        const [clientNames, setClientNames] = useState({});

        useEffect(() => {
            const fetchClientNames = async () => {
                const names = {};
                for (const projeto of Object.keys(projetosList)) {
                    const nome = await getClientByProject(projeto);
                    names[projeto] = nome;
                }
                setClientNames(names);
            };

            fetchClientNames();
        }, [projetosList]);

        return (
            <>
                {Object.keys(projetosList).length > 0  ?
                <>
                    <Button onClick={() => exportDataInExcel(clientNames)} className='mb-3'>Exportar</Button>
                    
                    {Object.keys(projetosList).map((projeto, index) => {
                        return (
                            <div key={index} className="mb-5">
                                <div className="d-flex  justify-content-between flex-column">
                                {data.radioValue == "cliente" ?
                                    <><h5><b>Cliente:</b> {data.cliente}</h5> <h5><b>Projeto:</b> {getProjectLabel(projeto)}</h5> <h5><b>Categoria:</b> {getProjectCategory(projeto)}</h5> </>
                                    :
                                    <><h5><b>Cliente:</b> {clientNames[projeto] ? `${clientNames[projeto]}` : " não cadastrado"}</h5> <h5><b>Projeto:</b> {getProjectLabel(projeto)}</h5> <h5><b>Categoria:</b> {getProjectCategory(projeto)}</h5> </>
                                }
                                </div>
                                <Table striped bordered hover>
                                    <thead>
                                        <tr>
                                            <th className="w-25">#</th>
                                            <th className="w-25">Período</th>
                                            <th className="w-25">Tipo de Custo</th>
                                            <th className="w-25">Valor</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {calculateTotals(projetosList[projeto])}
                                    </tbody>
                                </Table>
                            </div>
                        )
                    })}
                </>
                :
                < TabelaValorHH labelTotal={true} /> 
                }

            </>)
    }

    return (
        <PageContainer title={"Histórico de custos"} actions={actions}>
            {loading ? (
                <Loaging message={"Carregando..."} />
            ) : tipoCustoHH ? (
                <TabelaValorHH />
            ) : Object.keys(projetosList).length > 0 || valorHH?.some(dataHH => dataHH.totalHH > 0) ? (
                <Tabela />
            ) : (
                <h5>Não existe dados de custos para os filtros selecionados</h5>
            )}
        </PageContainer>
    )
}
export default HistoricoCustosProjetosClientesView
